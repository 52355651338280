import request from '@/utils/request'

const URL = {
  sendImageUrl: '/api/chat/SendImage',
}
/**
 * 发送图片聊天消息
 * @param {int} friendId
 * @param {Object} files
 */
export function sendImage(friendId, files) {
  return request({
    url: URL.sendImageUrl,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { friendId },
    transformRequest: [
      function () {
        return files
      },
    ],
    data: files,
  })
}
