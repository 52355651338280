<template>
  <div class="chat_button">
    <div
      style="font-size: 30px; line-height: 50px; text-align: center"
      @click="chatDialogVisible = true"
    >
      <el-badge :hidden="allUnreadMessages === 0" :value="allUnreadMessages">
        <i class="el-icon-headset"></i>
      </el-badge>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      append-to-body
      title="在线聊天框"
      :visible.sync="chatDialogVisible"
    >
      <div slot="title"></div>
      <div style="display: flex">
        <div class="add_firend">
          <el-input
            size="mini"
            placeholder="筛选好友"
            prefix-icon="el-icon-search"
            v-model="userNameFilterKeyWorld"
            clearable
          >
          </el-input>
          <el-badge :hidden="!isHaveFriendsApply" is-dot>
            <div class="add_firend_btn"><i class="el-icon-plus"></i></div>
          </el-badge>
        </div>
        <div class="user_name">
          <i class="el-icon-user-solid"></i>
          {{ nowChatUserInfo.FriendUserName }}
          <span v-show="isSelectUser">{{
            '[' + nowChatUserInfo.messageData.length + ']'
          }}</span>
          <span v-show="isSelectUser" style="float: right">
            <el-link
              icon="el-icon-upload2"
              :underline="false"
              @click="setMsgBoxScrollbarPoint(0)"
              >回到顶部</el-link
            >
            <el-link
              icon="el-icon-download"
              :underline="false"
              @click="setMsgBoxScrollbarPoint(0, false)"
              >回到底部</el-link
            >
          </span>
        </div>
      </div>
      <div class="flex_box" style="height: 500px">
        <div class="left-container">
          <div class="user_scrollbar_box">
            <el-scrollbar>
              <div class="user_list">
                <div
                  :class="[
                    'user_friend_box',
                    i.FriendUserId == nowChatUserInfo.FriendUserId
                      ? 'user_friend_box_active'
                      : '',
                  ]"
                  v-for="i in filterFriends"
                  :key="i.FriendUserId"
                  :title="i.FriendUserName"
                  @click="selectNowChatUser(i)"
                >
                  <div class="name">
                    <el-badge
                      :value="i.IsOnline ? '在线' : '离线'"
                      :type="i.IsOnline ? 'success' : 'info'"
                    >
                      <el-avatar icon="el-icon-user-solid"></el-avatar>
                    </el-badge>
                    <span class="name_span">{{ i.FriendUserName }}</span>
                  </div>
                  <span v-show="i.messageCount != 0" class="msg_point">{{
                    i.messageCount
                  }}</span>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="right-container">
          <div class="top-container">
            <el-scrollbar class="msg_box_scrollbar" ref="msgBoxRef">
              <div class="message_box">
                <div class="more_link">
                  <el-link
                    v-show="isSelectUser"
                    :underline="false"
                    type="success"
                    @click="
                      getMoreMsg(
                        nowChatUserInfo.FriendUserId,
                        ++nowChatUserInfo.messagePageIndex
                      )
                    "
                    >更多<i class="el-icon-more"></i
                  ></el-link>
                </div>
                <div
                  v-for="(i, index) in nowChatUserInfo.messageData"
                  :key="'msg' + index"
                >
                  <div
                    class="message_time_box"
                    :style="{
                      textAlign:
                        nowChatUserInfo.FriendUserId == i.FromUserId
                          ? 'left'
                          : 'right',
                    }"
                  >
                    {{ i.CreateTime | timeFormat('yyyy-MM-dd hh:mm:ss') }}
                  </div>
                  <div
                    v-if="nowChatUserInfo.FriendUserId == i.FromUserId"
                    class="message_box_left"
                  >
                    <el-avatar
                      class="heat_icon"
                      icon="el-icon-user-solid"
                    ></el-avatar>
                    <div v-if="i.MsgType == 'image'" class="msg_info_box">
                      <el-image
                        style="max-width: 100px"
                        :src="imageBaseUrl + i.MessageBody"
                        :preview-src-list="[imageBaseUrl + i.MessageBody]"
                        fit="contain"
                      ></el-image>
                    </div>
                    <div v-else class="msg_info_box">
                      {{ i.MessageBody }}
                    </div>
                  </div>
                  <div v-else class="message_box_right">
                    <div v-if="i.MsgType == 'image'" class="msg_info_box">
                      <el-image
                        style="max-width: 100px"
                        :src="imageBaseUrl + i.MessageBody"
                        :preview-src-list="[imageBaseUrl + i.MessageBody]"
                        fit="contain"
                      ></el-image>
                    </div>
                    <div v-else class="msg_info_box">
                      {{ i.MessageBody }}
                    </div>
                    <el-avatar class="heat_icon">我</el-avatar>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="bottom-container">
            <el-input
              :rows="4"
              :autosize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
              v-model="sendMsg"
              :disabled="!isCanSendMsg"
              @keyup.enter.native="sendMessage"
              :maxlength="500"
            >
            </el-input>
            <input
              type="file"
              accept=".png, .jpeg,.jpg"
              ref="chatFileRef"
              style="display: none"
              @change="chatFileDialogChange"
            />

            <el-button-group class="send_btn">
              <el-button
                @click="openFileSelectDialog"
                :disabled="!isCanSendMsg"
                size="mini"
                >发送图片</el-button
              >
              <el-button
                @click="sendMessage"
                :disabled="!isCanSendMsg"
                size="mini"
                >发送</el-button
              >
            </el-button-group>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import splitPane from 'vue-splitpane'
import { getToken } from '@/utils/auth'
import 'signalr'
import { getColorHexToTxt } from '@/utils/color'
import { sendImage } from '@/api/chat'

export default {
  name: 'ChatButton',
  components: { splitPane },
  data() {
    return {
      // chat代理
      proxy: null,
      // 在线聊天框显示隐藏
      chatDialogVisible: false,
      // 用户名筛选
      userNameFilterKeyWorld: '',
      sendMsg: '',
      nowChatUserInfo: {
        FriendUserId: null,
        FriendUserName: '',
        IsOnline: false,
        messageCount: 0,
        messagePageIndex: 1,
        messageData: [],
      },
      // 所有未读消息
      allUnreadMessages: 0,

      // 好友列表
      firendsData: [],
      // 好友申请列表
      firendsApplyData: [],
      fileBase64String: '',
      imageBaseUrl: process.env.VUE_APP_API_URL,
    }
  },
  methods: {
    // 初始化websocket
    initWs() {
      let hubUrl = process.env.VUE_APP_API_URL + '/chatHub'
      let hub = $.hubConnection(hubUrl)

      let token = getToken()

      if (token) {
        hub.qs = { 'X-Token': getToken() }
      }

      // 创建代理
      this.proxy = hub.createHubProxy('chatHub')

      // 异常信息回调
      this.proxy.on('exceptionCallback', (msg) => {
        this.$message.error(msg)
      })

      // 获取好友列表回调
      this.proxy.on('getFriendListCallback', (data) => {
        // 获取好友列表
        this.firendsData = data.map((item) => {
          return {
            ...item,
            messageCount: 0,
            messagePageIndex: 1,
            messageData: [],
          }
        })
      })

      // 获取好友请求列表的回调
      this.proxy.on('getAllFriendsApplyCallback', (data) => {
        this.firendsApplyData = data
      })

      // 收到消息时的回调
      this.proxy.on('receiveMessageCallback', (data) => {
        this.firendsData.forEach((item, index, arr) => {
          if (item.FriendUserId == data.FromUserId) {
            arr[index].messageData.push(data)
            arr[index].messageCount++
            this.allUnreadMessages++
          }
        })
        this.setMsgBoxScrollbarPoint(0, false)
      })

      // 有用户上下线时的回调
      this.proxy.on('hasOnlineOutlineCallback', (userid, isOnline) => {
        this.firendsData.forEach((item, index, arr) => {
          if (item.FriendUserId == userid) {
            arr[index].IsOnline = isOnline
          }
        })
      })

      // 当数据发送成功的回调
      this.proxy.on('sendMessageCallback', (data) => {
        // 向消息接收方,追加消息
        this.firendsData.forEach((item, index, arr) => {
          if (item.FriendUserId == data.ToUserId) {
            arr[index].messageData.push(data)
          }
        })
        this.setMsgBoxScrollbarPoint(0, false)
      })

      // 当有新的出货单消息时
      this.proxy.on('getNewOutStorageCallback', (data) => {
        const route = this.$route
        const { meta, path } = route

        let notification = new Notification(data.title, {
          dir: 'ltr',
          lan: 'zh-cn',
          body: data.body,
          requireInteraction: true,
        })

        if (path == '/OutStorage') {
          //          const h = this.$createElement

          // this.$notify({
          //   title: '您有新的出货单请注意',
          //   duration: 4500,
          //   type: 'warning',
          //   position: 'bottom-right',
          //   offset: 100,
          //   message: h('span', { style: 'color: red' }, data),
          // })
          this.$emit('OtherEvent')
        }
      })

      // 获取用户聊天记录时的回调
      this.proxy.on(
        'getUserMessageLogCallback',
        (friendId, pageIndex, result) => {
          this.firendsData.forEach((item, index, arr) => {
            if (item.FriendUserId == friendId) {
              let length = arr[index].messageData.length
              arr[index].messageData.splice(0, length)
              result.forEach((ritem) => {
                arr[index].messageData.push(ritem)

                // this.setMsgBoxScrollbarPoint(0, false)
              })
              // arr[index].messagePageIndex = pageIndex + 1
            }
          })

          setTimeout(() => {
            this.setMsgBoxScrollbarPoint(0, false)
          }, 500)
        }
      )

      hub
        .start()
        .done((connection) => {
          this.proxy.invoke('getFriendList')
          this.proxy.invoke('getFriendsApply')
        })
        .fail((error) => {
          this.$message.error('在线聊天功能,连接失败,请刷新页面进行重新尝试')
        })

      // 重连
      hub.disconnected(function () {
        setTimeout(() => {
          hub.start()
        }, 5000)
      })
    },
    // 根据名字获取颜色
    getColor(str) {
      return getColorHexToTxt(str)
    },
    // 设置聊天框位置
    setMsgBoxScrollbarPoint(val, isTop = true) {
      let nowVal = val
      if (!isTop) {
        nowVal = this.$refs['msgBoxRef'].$refs['wrap'].scrollHeight - val
      }

      this.$nextTick(() => {
        this.$refs['msgBoxRef'].$refs['wrap'].scrollTop = nowVal
      })
    },
    // 选中当前聊天对象
    selectNowChatUser(i) {
      if (this.allUnreadMessages >= i.messageCount) {
        this.allUnreadMessages -= i.messageCount
      } else {
        this.allUnreadMessages = 0
      }
      i.messageCount = 0
      this.nowChatUserInfo = { ...i }

      this.getMoreMsg(i.FriendUserId, i.messagePageIndex)

      this.setMsgBoxScrollbarPoint(0, false)
    },
    // 用户发送数据
    sendMessage() {
      if (this.nowChatUserInfo.FriendUserId && this.sendMsg) {
        this.proxy.invoke(
          'sendMessage',
          this.nowChatUserInfo.FriendUserId,
          this.sendMsg
        )
        this.sendMsg = ''
      } else {
        this.$message.error('当前选中对象不能为空,切消息不能为空')
      }
    },
    // 获取相关对象的更多消息
    getMoreMsg(userId, pageIndex) {
      this.proxy.invoke('getUserMessageLog', userId, pageIndex)
    },
    // 打开文件选中框
    openFileSelectDialog() {
      this.$refs.chatFileRef.click()
    },
    // 文件选择更改
    async chatFileDialogChange() {
      let file = this.$refs.chatFileRef.files[0]

      // 文件发送完成之后清空选中文件
      this.$refs.chatFileRef.value = ''
      this.fileBase64String = ''

      let fileSize = file.size,
        fileType = file.type
      // 判断文件路径格式
      if (file.name.lastIndexOf('.') == -1) {
        //如果不存在"."
        this.$message.error('当前选中文件路径不正确')
        return
      }
      let AllImgExt = '.jpg|.jpeg|.png|'
      let extName = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase()

      // 判断是否是图片格式
      if (AllImgExt.indexOf(extName + '|') == -1) {
        this.$message.error('上传文件只能是jpg,jpeg,png图片格式!')
        return
      }
      // 判断文件是否超大小
      if (fileSize > 1024 * 1024 * 2) {
        this.$message.error('上传文件大小不能超过 2M!')
        return
      }

      let formData = new FormData()

      formData.append('file', file)

      await sendImage(this.nowChatUserInfo.FriendUserId, formData)
      // let readers = new FileReader()

      // readers.onload = (e) => {
      //   console.log(11111111)
      //   this.fileBase64String = e.target.result

      //   this.proxy.invoke(
      //     'sendImage',
      //     this.nowChatUserInfo.FriendUserId,
      //     this.fileBase64String
      //   )

      //   console.log(222222)

      //   this.fileBase64String = ''
      // }
      // readers.readAsDataURL(file)
    },
  },
  created() {
    // 初始化websocket
    this.initWs()
  },
  // 计算属性
  computed: {
    // 判断当前是否有选中的对象
    isSelectUser() {
      return this.nowChatUserInfo.FriendUserId
    },
    // 判断是否能够发送消息,如果好友在线则能够发送消息
    isCanSendMsg() {
      return this.nowChatUserInfo.IsOnline
    },
    // 筛选之后的用户
    filterFriends() {
      let arr = this.firendsData
      if (this.userNameFilterKeyWorld) {
        arr = this.firendsData.filter((item) => {
          return item.FriendUserName.indexOf(this.userNameFilterKeyWorld) != -1
        })
      }

      return arr
    },
    // 判断是否拥有代办请求业务
    isHaveFriendsApply() {
      return this.firendsApplyData.length > 0
    },
  },
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.chat_button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.flex_box {
  display: flex;
}

.add_firend {
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  .add_firend_btn {
    padding: 6px;
    border-radius: 4px;
    margin: 4px 0 4px 4px;
    background-color: #e2e2e2;
    cursor: pointer;
  }
  .el-input {
    flex-grow: 1;
  }
}

.user_name {
  flex-grow: 1;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e5e5e5;
  line-height: 30px;
}

.left-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  // background-color: #f38181;
  height: 100%;

  .user_scrollbar_box {
    width: 100%;
    height: 500px;
  }
  .user_list {
    flex-grow: 1;
    height: 500px;
    .user_friend_box {
      display: flex;
      width: 100%;
      height: 80px;
      padding: 0 10px;
      box-sizing: border-box;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      background-color: #e4e4e4;
      border-bottom: 1px solid #efefef;
      .name {
        display: flex;
        align-items: center;
        .name_span {
          padding-left: 5px;
          max-width: 100px;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .msg_point {
        padding: 2px 5px;
        font-size: 12px;
        color: white;
        background-color: #fa5151;
        border-radius: 10px;
      }
    }
    .user_friend_box:hover {
      background-color: #d7d7d7;
    }
    .user_friend_box_active {
      background-color: #c6c6c5;
    }
    .user_friend_box_active:hover {
      background-color: #c6c6c5;
    }
  }
}

.right-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // background-color: #fce38a;
}

.top-container {
  flex-grow: 1;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}

.message_box {
  height: 400px;
  padding: 10px;
  box-sizing: border-box;

  .more_link {
    text-align: center;
  }
  .message_time_box {
    display: block;
    font-size: 12px;
    color: #1e1e1e;
    margin: 5px 60px;
    overflow: auto;
  }

  .message_box_left,
  .message_box_right {
    display: flex;
    padding-bottom: 10px;
    .heat_icon {
      flex-shrink: 0;
    }
    .msg_info_box {
      max-width: 300px;
      padding: 10px 20px;
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }

  .message_box_left {
    justify-content: flex-start;
    .msg_info_box {
      margin-left: 15px;
      background-color: white;
    }
  }

  .message_box_right {
    justify-content: flex-end;
    .msg_info_box {
      margin-right: 15px;
      background-color: #95e165;
    }
  }
}

.bottom-container {
  display: flex;
  position: relative;
  width: 100%;
  background-color: white;
  height: 100px;

  .send_btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
</style>
