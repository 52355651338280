import { getPermission } from '@/api/user'
import { constantRoutes, asyncRoutes } from '@/router/index.js'
import { getShortcut, setShortcut, removeShortcut } from '@/utils/auth.js'
import path from 'path'

const state = {
  routes: [],
  addroutes: [],
  shortcutRoutes: getShortcut(),
}

function resolvePath (path1, path2) {
  return path.resolve(path1, path2)
}

export function filterAsyncRoutes (routes, roleUrl) {
  const res = []
  routes.forEach((route) => {
    const tmp = { ...route }

    if (tmp.children) {
      if (
        tmp.children.some((u) => {
          return roleUrl.some((r) => {
            return r == resolvePath(tmp.path, u.path)
          })
        })
      ) {
        tmp.children = tmp.children.filter((u) => {
          return roleUrl.some((r) => {
            return r == resolvePath(tmp.path, u.path)
          })
        })

        res.push(tmp)
      }
    } else if (tmp.path == '*') {
      res.push(tmp)
    } else if (tmp.children == undefined) {

      console.log("roleUrl.path",roleUrl)
      console.log("tmp.path",tmp.path)

      if (roleUrl.some((r) => {
        return r == tmp.path
      })) {

        res.push(tmp)
      }
    }
  })
  return res
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addroutes = routes
    state.routes = constantRoutes.concat(routes)
  },
}

const actions = {
  generateRoutes ({ commit }, obj) {
    return new Promise((resolve, reject) => {
      let accessedRoutes

      if (
        obj.mailUserRole == 1 ||
        obj.userName == 'admin' ||
        obj.userName == 'snowyitest'
      ) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, obj.rolePageUrls)
      }

      commit('SET_ROUTES', accessedRoutes)

      resolve(accessedRoutes)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
