/*
 * 过滤特殊字符
 */
export function stripscript(str) {
  var pattern = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）&;—|{ }【】‘；：”“'。，、？]"
  )
  var rs = ''
  for (var i = 0; i < str.length; i++) {
    rs = rs + str.substr(i, 1).replace(pattern, '')
  }
  return rs
}

/*
 * 验证手机号
 */
export function validatePhone(value) {
  let reg = /^1[0-9]{10}$/
  return reg.test(value)
}

/*
 * 验证邮箱
 */
export function validateEmail(value) {
  let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
  return reg.test(value)
}

/*
 * 验证密码
 */
export function validatePwd(value) {
  let reg = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/
  return reg.test(value)
}

/*
 * 验证验证码
 */
export function validateICode(value) {
  let reg = /^[a-z0-9]{6}$/
  return reg.test(value)
}

/*
 * 验证数字
 */
export function validateNumber(value) {
  return !isNaN(value)
}

// 判断是否是外部请求
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
