import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

const BASEURL = process.env.VUE_APP_API_URL
//const BASEURL = 'http://localhost:58967'
//const BASEURL = 'http://192.168.1.114'
//const BASEURL = 'https://oa.hopebeauty.com'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: BASEURL,
  //timeout: 5000,
})

// 请求守卫
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers['X-Token'] = getToken()
    }

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async (response) => {
    // 文件流下载部分
    if (
      response.headers['content-type'].indexOf('application/octet-stream') != -1
    ) {
      // axios下载文件 参考文章地址:https://blog.csdn.net/tears_520/article/details/103110467
      let filename = response.headers['content-disposition']
      filename = decodeURI(filename.split(';')[1].split('filename=')[1])
      const content = response.data
      const blob = new Blob([content])

      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, file)
      }

      return
    }

    let promise = null

    let res = response.data

    if (Object.prototype.toString.call(response.data) === '[object Blob]') {
      // 先判断是不是blob格式
      if (response.data.type.includes('application/json')) {
        promise = new Promise((resolve, reject) => {
          // 判断是不是特殊情况(json)
          let reader = new FileReader()
          reader.onload = function () {
            let resr = reader.result
            res = JSON.parse(resr) // blob格式转成json
            resolve(res)
          }
          reader.readAsText(response.data)
        })
      }
    }

    if (promise != null) {
      await promise
    }

    if (res.code !== 200) {
      // 数据不等于200时表示请求拥有错误
      Message({ message: res.message, type: 'error', duration: 5 * 1000 })
      // 401表示用户登录失效
      if (res.code === 401) {
        MessageBox.confirm('当前登录凭证已经过期,请重新登录!', '退出登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            // 刷新页面
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      // code 等于200 表示请求完成
      return res
    }
  },
  (error) => {
    Message({ message: error.message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)

export default service
