// 根据文字内容来生成对应的颜色hex值
export function getColorHexToTxt(str) {
  var val = str
  var tmp = ''
  // 将获取到的字符串先转成unicode码再转成16位数字
  for (var i = 0; i < val.length; i++) {
    tmp += val[i].charCodeAt().toString(16)
  }
  // 判断转化为16位后字符串长度
  if (tmp.length > 6) {
    // 长度大于6时取后6位作为颜色值
    tmp = tmp.substr(-6)
  } else if (tmp.length > 3) {
    // 长度小于6大于3时取后3位作为颜色值
    tmp = tmp.substr(-3)
  } else {
    // 若长度小于三则设置默认色
    tmp = 'aquamarine'
  }
  return `#${tmp}`
}
