const getters = {
  token: (state) => state.user.token,
  headImage: (state) => state.user.headImage,
  name: (state) => state.user.name,
  usrId: (state) => state.user.userId,
  roles: (state) => state.user.roles,
  routes: (state) => state.permission.routes,
  fold: (state) => state.user.fold
}
export default getters
