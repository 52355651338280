import { getToken, setToken, removeToken } from '@/utils/auth'
import { login, logout, getInfo } from '@/api/user'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  userId: '',
  headImage: '',
  roles: [],
  fold: true,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_HEADIMAGE: (state, headImage) => {
    state.headImage = headImage
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_FOLD: (state, data) => {
    state.fold = data
  }
}

const actions = {
  // 用户登录
  login ({ commit }, userinfo) {
    const { username, password } = userinfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          // console.log('用户数据', data)
          commit('SET_TOKEN', data)
          setToken(data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 获取用户信息
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response
          if (!data) {
            reject('当前用户不存在请重新登录')
          }

          const { userRole, userName, headImage, userId, RolePageUrls } = data

          if (!userRole) {
            reject('当前账号不被允许访问')
          }

          commit('SET_ROLES', userRole)
          commit('SET_NAME', userName)
          commit('SET_USERID', userId)
          commit('SET_HEADIMAGE', headImage)

          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // 退出登录
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_NAME', '')
      commit('SET_USERID', '')
      commit('SET_HEADIMAGE', '')

      removeToken()

      resetRouter()

      resolve()

      //   logout(state.token)
      //     .then(() => {})
      //     .catch((error) => {
      //       reject(error)
      //     })
    })
  },
  // remove token
  resetToken ({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_NAME', '')
      commit('SET_USERID', '')
      commit('SET_HEADIMAGE', '')
      removeToken()
      resolve()
    })
  },
  toggleFold ({ commit }) {
    commit('SET_FOLD', !state.fold)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
