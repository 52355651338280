import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/main.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './permission'

import * as filters from './filters'

console.log(process.env.NODE_ENV)

if (process.env.NODE_ENV === 'production') {
  let url = window.location.href
  if (url.indexOf('https') < 0) {
    url = url.replace('http:', 'https:')
    window.location.replace(url)
  }
}

try {
  if (Notification.permission === 'default') {
    Notification.requestPermission()
  }
} catch (error) { }

Vue.config.productionTip = false

Vue.prototype.$deepCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.use(ElementUI, {
  "size": 'small'
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
