<template>
  <div id="app">
    <router-view ref="child_router_view" />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>
#app {
  height: 100%;
}
</style>
