import request from '@/utils/request'

const URL = {
  loginUrl: '/api/user/Login',
  getInfoUrl: '/api/user/getUserInfo',
  logoutUrl: '',
  getPermission: '/api/user/getUserRoleRouter',
  getUserList: 'api/user/getUserList',
  changeUserStaet: 'api/user/changeUserState',
  changeCustomerName: 'api/user/changeCustomerName',
  getUserAddressList: 'api/user/GetUserAddressList',
  addUserContactDetail: 'api/user/addUserContactDetail',
  setUserDefaultAddress: 'api/user/setUserDefaultAddress',
  deleteUserAddress: 'api/user/deleteUserAddress',
  editUserContactDetail: 'api/user/editUserContactDetail',
  getUserOptions: 'api/user/getUserOptions',
}

// 登录
export function login(data) {
  return request({
    url: URL.loginUrl,
    method: 'post',
    data,
  })
}

// 登录失败
export function getInfo() {
  return request({
    url: URL.getInfoUrl,
    method: 'get',
  })
}

// 退出登录
export function logout() {
  return request({
    url: URL.logoutUrl,
    method: 'post',
  })
}

// 获取权限信息
export function getPermission() {
  return request({
    url: URL.getPermission,
    method: 'get',
  })
}

// 获取用户表
export function getUserList(data) {
  return request({
    url: URL.getUserList,
    method: 'post',
    data,
  })
}

// 更改用户状态
export function changeUserState(data) {
  return request({
    url: URL.changeUserStaet,
    method: 'post',
    data,
  })
}

// 更改用户备注名称
export function changeCustomerName(id, customerName) {
  return request({
    url: URL.changeCustomerName,
    method: 'get',
    params: { id, customerName },
  })
}

// 获取用户地址信息
export function getUserAddressList(data) {
  return request({
    url: URL.getUserAddressList,
    method: 'get',
    params: { id: data },
  })
}

// 新增用户地址
export function addUserContactDetail(data) {
  return request({
    url: URL.addUserContactDetail,
    method: 'post',
    data,
  })
}

// 编辑用户地址
export function editUserContactDetail(data) {
  return request({
    url: URL.editUserContactDetail,
    method: 'post',
    data,
  })
}

// 修改默认用户地址
export function setUserDefaultAddress(data) {
  return request({
    url: URL.setUserDefaultAddress,
    method: 'get',
    params: { id: data },
  })
}

// 删除用户地址
export function deleteUserAddress(data) {
  return request({
    url: URL.deleteUserAddress,
    method: 'get',
    params: { id: data },
  })
}

// 获取用户选项
export function getUserOptions(key) {
  return request({
    url: URL.getUserOptions,
    method: 'get',
    params: { key },
  })
}

/**
 * 更新用户角色权限
 * @param {*} data
 * @returns
 */
export function updateUserRoleCode(data) {
  return request({
    url: 'api/user/UpdateUserRole',
    method: 'post',
    data,
  })
}

// 更改用户所属供应商
export function changeUserOwinSupplier(data) {
  return request({
    url: 'api/user/ChangeUserOwinSupplier',
    method: 'post',
    data,
  })
}
