import Cookies from 'js-cookie'

const TokenKey = 'Token'

const ShortcutIcon = 'Shortcut'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 获取快捷路由
export function getShortcut() {
  return Cookies.get(ShortcutIcon)
}
export function setShortcut(value) {
  return Cookies.set(ShortcutIcon, value)
}
export function removeShortcut(ShortcutIcon) {
  return Cookies.remove(ShortcutIcon)
}
